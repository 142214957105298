
import { computed, defineComponent, onMounted, reactive, ref, provide, nextTick } from "vue";
import { IonSlides, IonSlide } from "@ionic/vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import CorrectPersonalDataCheck from "@/views/Onboarding/TrainingContract/CorrectPersonalDataCheck.vue";
import CorrectData from "@/views/Onboarding/TrainingContract/CorrectData.vue";
import CorrectDataAddons from "@/views/Onboarding/TrainingContract/CorrectDataAddons.vue";
import { useStore } from "vuex";
import Agb from "@/views/Onboarding/Agb.vue";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import UserService from "@/services/UserService";
import moment from "moment";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "PersonalData",
  components: {
    Agb,
    CorrectDataAddons,
    CorrectData,
    CorrectPersonalDataCheck,
    DefaultLayout,
    IonSlides,
    IonSlide,
  },
  setup() {
    const slider = ref(null);
    const store = useStore();
    const currentIndex = ref<number>(0);
    const router = useRouter();
    const addonsByCurrentStudent = async (options: any) => {
      await store.dispatch("price/addonsByStudentEducation/findAll", options);
    };
    const addonsFromCurrentStudent = computed(() => store.getters["price/addonsByStudentEducation/getDataList"]);
    const addonsFromCurrentStudentLoading = computed(() => store.getters["price/addonsByStudentEducation/getIsLoading"]);

    onMounted(() => {
      addonsByCurrentStudent({
        resource: "prices/addons-by-student/current",
        hideSuccessMessage: true,
      });
    });

    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      noSwiping: true,
      noSwipingClass: "swiper-no-swiping",
      autoHeight: true,
    };

    const adultUser = computed(() => {
      const user = UserService.getUser();
      return moment().diff(user.birthDate, "years") >= 18;
    });

    const correctDataFrom = reactive({
      generalTermsAndConditionsPriceFee: null,
    });

    const correctDataAddonsFrom = reactive({
      confirmBookingPaidAddons: null,
    });

    const form = reactive({
      confirmCollectAndProcessYourData: null,
      confirmFeeBasedTrainingContract: null,
      confirmParentAgreement: null,
    });

    const correctDataFromValidation = useVuelidate(
      {
        generalTermsAndConditionsPriceFee: { required },
      },
      correctDataFrom
    );

    const correctDataAddonsFromValidation = useVuelidate(
      {
        confirmBookingPaidAddons: { required },
      },
      correctDataAddonsFrom
    );

    const formValidation = useVuelidate(
      {
        confirmCollectAndProcessYourData: { required },
        confirmFeeBasedTrainingContract: { required },
        confirmParentAgreement: {
          required: requiredIf(() => {
            return !adultUser.value;
          }),
        },
      },
      form
    );

    const next = (value: number) => {
      currentIndex.value = value;
      //@ts-ignore
      slider.value.$el.slideTo(value);
    };

    const addonsNext = () => {
      if (addonsByCurrent.value && addonsByCurrent.value.length) {
        currentIndex.value = 2;
        //@ts-ignore
        slider.value.$el.slideTo(2);
      } else {
        currentIndex.value = 3;
        //@ts-ignore
        slider.value.$el.slideTo(3);
      }
    };

    const getDrivingSchool = computed(() => store.getters["public-info/getDrivingSchool"]);

    const acceptContract = async () => {
      await store.dispatch("student/acceptContract/update", {
        resource: `/students/current/accept-contract`,
      });
    };

    const acceptContractSuccess = computed(() => store.getters["student/acceptContract/getSuccess"]);

    const onSubmit = async () => {
      await acceptContract();

      if (acceptContractSuccess.value) {
        // Update user
        const user = UserService.getUser();
        user.contractAccepted = true;
        await UserService.setUser(user);
        await router.push({ name: "Home" });
      }
    };

    const customBackButtonFunc = () => {
      currentIndex.value--;
      next(currentIndex.value);
    };

    const useCustomFunc = computed(() => currentIndex.value > 0);
    const addonsByCurrent = computed(() => {
      return addonsFromCurrentStudent.value.filter((addon: any) => addon.booked === true);
    });

    provide("updateSlideHeight", () => {
      nextTick(() => {
        //@ts-ignore
        slider.value?.$el?.updateAutoHeight();
      });
    });

    return {
      slideOpts,
      next,
      slider,
      getDrivingSchool,
      onSubmit,
      customBackButtonFunc,
      useCustomFunc,
      form,
      correctDataFrom,
      correctDataAddonsFrom,
      correctDataFromValidation,
      correctDataAddonsFromValidation,
      formValidation,
      addonsFromCurrentStudent,
      addonsFromCurrentStudentLoading,
      addonsByCurrent,
      adultUser,
      addonsNext,
    };
  },
});
