
import { computed, defineComponent } from "vue";
import UserService from "@/services/UserService";

export default defineComponent({
  name: "CorrectPersonalDataCheck",
  components: {},
  emits: ["next"],
  setup(props, { emit }) {
    const onClickYes = () => {
      emit("next");
    };
    const user = computed(() => UserService.getUser());
    return {
      onClickYes,
      user,
    };
  },
});
