
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "GeneralTermsAndConditions",
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    modelValue: {},
    checkboxTextClass: {
      type: String,
    },
    invalid: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit, slots }) {
    const onChangeModel = (value: any) => {
      emit("update:modelValue", value);
    };

    const hasDefaultSlot = computed(() => slots.default);

    return {
      onChangeModel,
      hasDefaultSlot,
    };
  },
});
